import { React, useState, useEffect, useRef } from "react";
import { Box, Button } from "@mui/material";
// import { Accordion, AccordionTab } from 'primereact/accordion'
import { Link } from "react-router-dom";
import PrintIcon from "@mui/icons-material/Print";
import { SERVER_API, API_URL } from "../../../common/env";
import { Calendar } from "primereact/calendar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import css from "./ViewTtn.module.css";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem", fontWeight: "bold" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function ViewTtn(props) {
  const toast = useRef();

  const [date, setDate] = useState(
    (() => {
      const d = new Date(),
        y = d.getFullYear(),
        m = d.getMonth();
      return [new Date(y, m, 1), new Date(y, m + 1, 0)];
    })()
  );
  const [item, setItem] = useState(null);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const datemy = "1.10.2023";

  const load = () => {
    setIsLoading(true);
    fetch(`${SERVER_API}/ttn/ent/?date1=${date[0]}&date2=${date[1]}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then((data) => {
        return data.json();
      })
      .then((json) => {
        const data = json.data;
        console.log('viewttn data',data);
        setItems(data);
      })
      .catch((err) => {
        console.log(err);
        toast.current.show({
          severity: "error",
          summary: "Помилка!",
          detail: `Помилка виконання`,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleLoadClick = (e) => {
    if (!date) {
      toast.show({
        severity: "error",
        summary: "Помилка!",
        detail: "Не вказано період!",
      });
      return;
    }
    load();
  };

  useEffect(() => {
    document.title = `EXMOTO: Просмотр накладных [${localStorage.getItem(
      "login"
    )}]`;
    load();
  }, []);

  const accordion = () => {
    // if (isLoading) return
    return items.map((item) => {
      let d = item.dt_created;
      d = d.substr(0, 10) + " " + d.substr(11, 5);
      return (
        <Accordion key={item.id}>
          <AccordionSummary
            sx={{
              "&:hover": {
                backgroundColor: "lightgray",
              },
            }}
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box>
              <Box >
                <table>
                  <tbody>
                    <tr className={css.tdDataTtnTr}>
                      <td className={css.tdDataTtn1}>{item.wb_no}</td>
                      <td className={css.tdDataTtn2}>
                        {item.dt_created.substr(0, 10)}
                      </td>
                      <td className={css.tdDataTtn3}>
                        {item.receiver_city_type_name}.{item.receiver_city_name}
                      </td>
                      <td className={css.tdDataTtn4}>{item.receiver_client_name}</td>
                      <td className={css.tdDataTtn5}>{item.receiver_street_name}</td>
                      <td className={css.tdDataTtn6}>{item.service_id}</td>
                      <td className={css.tdDataTtn7}>{item.place_count}</td>
                      <td className={css.tdDataTtn8}>{item.weight}</td>
                      <td className={css.tdDataTtn9}>
                        {item.P_D_IN ? item.P_D_IN.substr(0, 10) : ""}{" "}
                        {item.P_D_IN ? item.P_D_IN.substr(11, 5) : ""}
                      </td>
                      <td className={css.tdDataTtn10}>{item.note}</td>
                    </tr>
                  </tbody>
                </table>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <a  className={css.printbutton} href={`${API_URL}/ttn/print/ent?id=${item.id}&jwt=${localStorage.getItem("jwt")}`} target="_blank" rel="noreferrer" >
              <PrintIcon />
              &nbsp;Друк&nbsp;
            </a>
            <Box className={css.boxclass}>
              <div > 
              <div className={css.ttnTopInfo}>
                <div className={css.ttnBoxInfo}>
                    <table>
                      <thead> <tr><th className={css.thDetailH2} colSpan={2}>Назва</th></tr> </thead>
                      <tbody className={css.tbDetailBorder}>
                        <tr><td className={css.tdDetailInfo}>Країна </td></tr>
                        <tr><td>Нас.пункт</td></tr>
                        <tr><td>Область</td></tr>
                        <tr><td>Район</td></tr>
                        <tr><td>Вулиця</td></tr>
                        {/* <tr><td>Будинок</td></tr>
                        <tr><td>Квартира</td></tr> */}
                        <tr><td>Компанiя</td></tr>
                        <tr><td>Контакта особа</td></tr>
                        <tr><td>Телефон</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <table>
                      <thead> <tr><th className={css.thDetailH2} colSpan={2}>Відправник</th></tr> </thead>
                      <tbody className={css.tbDetailBorder}>
                        <tr><td className={css.tdDetailInfo}>{item.sender_country_name}</td></tr>
                        <tr><td className={css.tdDetailInfo}>{item.org}&nbsp;&nbsp;{item.sender_city_type_name}.{item.sender_city_name}</td></tr>
                        <tr><td className={css.tdDetailInfo}>{item.sender_state_name} {item.sender_state_type_name}</td></tr>
                        <tr><td className={css.tdDetailInfo}>{item.sender_distr_name || " - "}</td></tr>
                        <tr><td className={css.tdDetailInfo}>{item.sender_street_name || " - " + item.sender_street_type || ""}</td></tr>
                        {/* <tr><td className={css.tdDetailInfo}>{item.sender_bldg || " - "}</td></tr>
                        <tr><td className={css.tdDetailInfo}>{item.sender_flat || " - "}</td></tr> */}
                        <tr><td className={css.tdDetailInfo}>{item.sender_client_name}</td></tr>
                        <tr><td className={css.tdDetailInfo}>{item.sender_contact_name}</td></tr>
                        <tr><td className={css.tdDetailInfo}>{item.sender_phone}</td></tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <table>
                      <thead> <tr> <th className={css.thDetailH2} colSpan={2}>Отримувач</th> </tr> </thead>
                      <tbody className={css.tbDetailBorder}>
                        <tr><td className={css.tdDetailInfo}>{item.receiver_country_name}</td></tr>
                      <tr><td className={css.tdDetailInfo}>{item.org}&nbsp;&nbsp;{item.receiver_city_type_name}.{item.receiver_city_name}</td> </tr>
                        <tr><td className={css.tdDetailInfo}>{item.receiver_state_name} {item.receiver_state_type_name} </td> </tr>
                        <tr><td className={css.tdDetailInfo}>{item.receiver_distr_name ? item.receiver_distr_name : " - "} </td> </tr>
                        <tr><td className={css.tdDetailInfo}>{item.receiver_street_name || " - " + item.receiver_street_type || ""} </td> </tr>
                        {/* <tr><td className={css.tdDetailInfo}>{item.receiver_bldg || " - "}</td> </tr>
                        <tr><td className={css.tdDetailInfo}>{item.receiver_flat || " - "}</td> </tr> */}
                        <tr><td className={css.tdDetailInfo}>{item.receiver_client_name}</td> </tr>
                        <tr><td className={css.tdDetailInfo}>{item.receiver_contact_name}</td> </tr>
                        <tr><td className={css.tdDetailInfo}>{item.receiver_phone}</td> </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <table>
                      <thead> <tr> <th className={css.thDetailH2} colSpan={2}>Параметри</th> </tr> </thead>
                      <tbody className={css.tbDetailBorder}>
                        {/* <tr> <td className={css.tdDetailInfo}>Платник</td> <td>{item.payer_name}</td></tr> */}
                        <tr> <td>Кількість місць</td> <td>{item.place_count}</td> </tr>
                        {/* <tr> <td>Сплата</td> <td>{item.payment_name}</td></tr> */}
                        <tr> <td className={css.tdDetailInfoParam2}>Формат</td> <td>{item.cargo_name}</td></tr>
                        <tr> <td>Доставка</td> <td>{item.delivery_name}</td></tr>
                        <tr> <td>Оціночна вартість, грн.</td> <td>{item.cost.toFixed(2)}</td></tr>
                        <tr> <td className={css.tdDetailInfo}>Сервіс</td> <td>{item.service_name}</td></tr>
                        {/* <tr> <td>Тип вкладення</td> <td>{item.attachment_name}</td></tr> */}
                        <tr> <td>Вага, кг</td> <td>{item.weight}</td></tr>
                      </tbody>
                    </table>
                  </div>
              </div>
              <div className={css.boxtextarea} data-note={"Oпис"}>
                <p className={css.ttnBoxInfo}>Примітка: {item.note}</p>
                { item.Ofvers ? (<p className={css.ttnBoxInfo}>Виключення: {item.Ofvers}</p>) : (<p></p>)}
              </div>
              </div>
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  return (
    <>
      <Box sx={{ padding: "5px 0" }}>
        <span className={css.calendarSpan}>Період</span>
        <Calendar
          className={css.calendarView}
          selectionMode="range"
          dateFormat="dd.mm.yy"
          value={date}
          onChange={(e) => setDate(e.value)}
          disabled={isLoading}
        />
        {isLoading ? (
          <CircularProgress size="20px" />
        ) : (
          <Button onClick={handleLoadClick}>
            <VisibilityIcon />
          </Button>
        )}
        <table>
          <thead className={css.thListTtn}>
            <tr>
              <th className={css.thListTtn1}>ТТН</th>
              <th className={css.thListTtn2}>Дата</th>
              <th className={css.thListTtn3}>Місто отримувача</th>
              <th className={css.thListTtn4}>Отримувач</th>
              <th className={css.thListTtn5}>Адреса</th>
              <th className={css.thListTtn6}>Послуга</th>
              <th className={css.thListTtn7}>Місць</th>
              <th className={css.thListTtn8}>Вага</th>
              <th className={css.thListTtn9}>Доставлено</th>
              <th className={css.thListTtn10}>Примітка</th>
            </tr>
          </thead>
        </table>
      </Box>

      {items.length ? (
        accordion()
      ) : (
        <span style={{ color: "gray", fontSize: "2em" }}>
          Відсутні дані для перегляду
        </span>
      )}
    </>
  );
}
