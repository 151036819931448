import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const MenuList = styled.ul`
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MenuItem = styled.li`
  margin: 0 20px;
  position: relative;
  cursor: pointer;
  padding: 10px;

  &:hover {
    .submenu {
      display: block;
    }
  }
`;

export const SubMenu = styled.ul`
  display: none;
  list-style-type: none;
  position: absolute;
  top: 100%;
  left: 50%; /* Center the submenu horizontally */
  transform: translateX(-50%); /* Center the submenu horizontally */
  max-width: 200px;
  background-color: #f9f9f9;
  z-index: 1;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);

  &.open {
    display: block;
    animation: ${fadeIn} 0.3s ease-in-out;
  }
`;

export const SubMenuItem = styled.li`
  margin: 0;
  padding: 10px 20px 10px 20px;
  width: 100%;
  text-align: center;
`;

export const StyledLogo = styled.img`
  width: 100%;
  height: 45px;
`;

export const MainNav = styled.div`
  display: flex;
`;

export const StyledNavLinkMain = styled(NavLink)`
  font-family: "Roboto", "Arial", "Helvetica", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  text-decoration: none;
  color: #333;

  &:hover {
    color: #007bff;
  }
`;

export const StyledNavLink = styled(NavLink)`
  font-family: "Roboto", "Arial", "Helvetica", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  text-decoration: none;
  color: #333;

  &:hover {
    color: #007bff;
  }
`;

export const LogoutItem = styled(MenuItem)`
  cursor: pointer;
`;

