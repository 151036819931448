import React from "react";
import { useState, useEffect, useRef } from "react";
import { SERVER_API } from "../../common/env.js";
import { Toast } from "primereact/toast";
import Button from "@mui/material/Button";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import City from "../Сity.js";

const CityContext = React.createContext();

export default function ManageClients(props) {
  const toast = useRef();

  const [city, setCity] = useState({ country_id: 20, city_id: null });
  const [address, setAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [person, setPerson] = useState("");
  const [edrpou, setEdrpou] = useState('');
  const [item, setItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const columns = [
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Редагувати"
          onClick={() => {
            setItem(params.row);
          }}
        />,
        // <GridActionsCellItem
        //   icon={<DeleteIcon />}
        //   label="Видалити"
        //   onClick={async () => {
        //     if (!window.confirm(`Видалити компанію ${params.row.c_co} ?`)) return
        //     const id = params.row.id
        //     try {
        //       const response = await fetch(`${SERVER_API}/clients/${id}`,
        //         {
        //           method: 'DELETE',
        //           headers: new Headers({ Authorization: 'Bearer ' + localStorage.getItem('jwt') })
        //         })
        //       if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
        //       const json = await response.json()
        //       toast.current.show({ severity: 'info', summary: '', detail: 'Запис видалено.', life: 3000 });
        //       load()
        //     } catch (err) {
        //       console.log(err)
        //       toast.current.show({ severity: 'error', summary: 'Помилка!', detail: `Помилка виконання` })
        //     } finally {
        //     }
        //   }}
        // />,
      ],
    },
    { field: "c_co", headerName: "Компанія", flex: 1 },
    { field: "full_adrs_ukr", headerName: "Місто", flex: 1 },
    { field: "c_adr", headerName: "Адреса", flex: 1 },
    { field: "c_name", headerName: "Контактна особа", flex: 1 },
    { field: "c_tel", headerName: "Телефон", flex: 1 },
    { field: "cacc", headerName: "CACC", flex: 1 },
    { field: "c_edrpou", headerName: "ЄДРПОУ", flex: 1 },
  ];

  const [data, setData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickNewCompany = () => {
   // setItem({});
    setAddress('');
      setCity({ country_id: 20, city_id: null });
      setCompanyName('');
      setPhone('');
      setEdrpou('');
      setPerson('');
      setOpenDialog(true);
    console.log('handleClNewComp typeof(edrpou)',typeof(edrpou))
  };

  useEffect(() => {(async () => await load())()}, []);

  useEffect(() => {
    if (item !== null) {
      setAddress(item.c_adr);
      setCity({ country_id: 20, city_id: item.c_cityid });
      setCompanyName(item.c_co);
      setPhone(item.c_tel);
      setEdrpou(item.c_edrpou);
      setPerson(item.c_name);
      setOpenDialog(true);
    }
  }, [item]);

  useEffect(() => {
    if (typeof props.setClient === "function") {
      props.setClient(selectedItem);
    }
  }, [selectedItem]);

  const load = async () => {
    try {
      const response = await fetch(`${SERVER_API}/clients`, {
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        }),
      });
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const json = await response.json();
      const data = json.data;
      setData(data);
      console.log('Load Clients ', data)
    } catch (err) {
      console.log(err);
      toast.current.show({
        severity: "error",
        summary: "Помилка!",
        detail: `Помилка виконання`,
      });
    } finally {
    }
  };

  const EditDialog = () => {
    const [validationErrors, setValidationErrors] = useState({});
   // console.log('EditDialog Client companyName, person, edrpou', companyName, person, edrpou);

    const handleClickSave = async () => {
      setValidationErrors({});
      const errors = {};
      if (!companyName.trim()) {
        console.log('hndl click save cmpname INSIDE IF')
        errors.companyName = "Компанія не може бути порожньою!";
      }

      if (!city.city_id) {
        errors.city = "Не вказаний населений пункт!";
      }
      //console.log('ADRESS', address)
      if (!address.trim()) {
        errors.address = "Адреса не може бути порожньою!";
      }

      if (!person.trim()) {
        errors.person = "Контактна особа не може бути порожньою!";
      }    
      const phoneRegex = /^\+38\d{10}$/;
      const isValidPhone = phoneRegex.test(phone)
      if (!isValidPhone) {
        errors.phone =
          "Неправильний формат телефону! Почніть з +38 і додайте 10 цифр.";
      }
      //const edrpouRegex = /^(\d{10})?$/;
      console.log('EDRPOU ЬС', typeof(edrpou))
      console.log('EDRPOU length', !!edrpou)
      if (!!edrpou) {
        const edrpouRegex = /^\d{8}(?:\d{2})?$/;
        const isValidEdrpou = edrpouRegex.test(edrpou);
       // console.log('EDRPOU', edrpou.trim(), edrpouRegex.test(edrpou))
        if (!isValidEdrpou) {
          errors.edrpou =
            "Неправильний формат коду ЄДРПОУ! Додайте 8 або 10 цифр.";
        }
      } 

      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      try {
        setValidationErrors({});
        const response =
          item === null
            ? await fetch(`${SERVER_API}/clients`, {
                method: "POST",
                headers: new Headers({
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("jwt"),
                }),
                body: JSON.stringify({
                  phone,
                  edrpou: edrpou.toString(),
                  company_name: companyName,
                  address,
                  city: city.city_id,
                  person,
                }),
              })
            : await fetch(`${SERVER_API}/clients/${item.id}`, {
                method: "PUT",
                headers: new Headers({
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("jwt"),
                }),
                body: JSON.stringify({
                  phone,
                  edrpou: edrpou.toString(),
                  company_name: companyName,
                  address,
                  city: city.city_id,
                  person,
                }),
              });

        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);
        load();
        toast.current.show({
          severity: "success",
          summary: "Повідомлення",
          detail: `Дані оновлено.`,
        });
        setOpenDialog(false);
      } catch (err) {
        console.log(err);
        toast.current.show({
          severity: "error",
          summary: "Помилка!",
          detail: `Помилка виконання`,
        });
      } finally {
      }
    };

    const handleCloseDialog = () => {
      setOpenDialog(false);
      setItem(null);
    };

    return (
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Редагування</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="CACC"
            margin="dense"
            fullWidth
            variant="outlined"
            value={item?.cacc || ""}
            disabled
          />
          <TextField
            autoFocus
            label="Компанія"
            required
            margin="dense"
            fullWidth
            variant="outlined"
            value={companyName}
            onChange={(e) => {setCompanyName(e.target.value)
              setValidationErrors({})}}
            error={!!validationErrors.companyName}
            helperText={validationErrors.companyName}
          />
          <City context={CityContext} 
          error={!!validationErrors.city}
          helperText={validationErrors.city}
          />
          <TextField
            label="Адреса"
            required
            margin="dense"
            fullWidth
            variant="outlined"
            value={address}
            onChange={(e) => {setAddress(e.target.value)
              setValidationErrors({})}}
            error={!!validationErrors.address}
            helperText={validationErrors.address}
          />
          <TextField
            label="Контактна особа"
            margin="dense"
            fullWidth
            variant="outlined"
            value={person}
            onChange={(e) => {setPerson(e.target.value)
              setValidationErrors({})}}
            error={!!validationErrors.person}
            helperText={validationErrors.person}
          />
          <TextField
            label="Телефон"
            margin="dense"
            fullWidth
            variant="outlined"
            value={phone}
            onChange={(e) => {setPhone(e.target.value)
              setValidationErrors({})}}
            error={!!validationErrors.phone}
            helperText={validationErrors.phone}
          />
          <TextField
            label="Код ЄДРПОУ клієнта за потребою"
            required
            margin="dense"
            fullWidth
            variant="outlined"
            value={edrpou}
          onChange={(e) => {setEdrpou(e.target.value)
            setValidationErrors({})}}
            error={!!validationErrors.edrpou}
            helperText={validationErrors.edrpou}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickSave}>Зберегти</Button>
          <Button onClick={handleCloseDialog}>Відмінити</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <CityContext.Provider value={[city, setCity]}>
        <Toast ref={toast} />
        <Box>
          {/* <h1>ManageClients</h1> */}
          <Button variant="text" onClick={handleClickNewCompany}>
            Додати
          </Button>

          <DataGrid
            autoHeight
            rows={data}
            columns={columns}
            onSelectionModelChange={(value) =>
              setSelectedItem(data.find((item) => item.id == value))
            }
          />
          {EditDialog()}
        </Box>
      </CityContext.Provider>
    </>
  );
}
