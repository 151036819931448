import React from "react";

import {
  MenuList,
  MenuItem,
  SubMenu,
  SubMenuItem,
  StyledNavLinkMain,
  StyledNavLink,
  StyledLogo,
  MainNav,
} from "./Menu.styled";

import siteLogo from "../../../img/logo_1.png";
import { ROUTES } from "../../../Routes/Rotuer";

export const Menu = () => {
  const hangleLogout = () => {
    localStorage.clear();
    window.location = ROUTES.MAIN;
  };

  return (
    <MenuList>
      <StyledNavLink to="/enterprise">
        <StyledLogo src={siteLogo} alt="logo" />
      </StyledNavLink>

      <MainNav>
        <MenuItem>
          <StyledNavLinkMain>ТТН</StyledNavLinkMain>
          <SubMenu className="submenu">
            <SubMenuItem>
              <StyledNavLink to="/enterprise">Перегляд</StyledNavLink>
            </SubMenuItem>
            <SubMenuItem>
              <StyledNavLink to="/enterprise/ttn">Додати</StyledNavLink>
            </SubMenuItem>
          </SubMenu>
        </MenuItem>

        <MenuItem>
          <StyledNavLinkMain>Налаштування</StyledNavLinkMain>
          <SubMenu className="submenu">
            <SubMenuItem>
              <StyledNavLink to="/enterprise/admin/users">
                Користувачі особистого кабінету
              </StyledNavLink>
            </SubMenuItem>
            <SubMenuItem>
              <StyledNavLink to="/enterprise/admin/payers">
                Платник
              </StyledNavLink>
            </SubMenuItem>
          </SubMenu>
        </MenuItem>

        <MenuItem>
          <StyledNavLinkMain>Звіти</StyledNavLinkMain>
          <SubMenu className="submenu">
            <SubMenuItem>
              <StyledNavLink to="/enterprise">
                Відправлення за період
              </StyledNavLink>
            </SubMenuItem>
            <SubMenuItem>
              <StyledNavLink to="/enterprise">
                Баланс по взаєморозрахункам
              </StyledNavLink>
            </SubMenuItem>
          </SubMenu>
        </MenuItem>

        <MenuItem>
          <StyledNavLinkMain to="/enterprise/client">Мої клієнти</StyledNavLinkMain>
        </MenuItem>
      </MainNav>

      <MenuItem onClick={hangleLogout}>
        <StyledNavLinkMain to="/">Вихід</StyledNavLinkMain>
      </MenuItem>
    </MenuList>
  );
};
